import { Engine, Actor, vec, SpriteSheet, Animation, range, ParticleEmitter, EmitterType, Vector, Color } from "excalibur";

import { Resources } from "../resources";
import { Config } from "../config";

export class LeafEmitter extends Actor {
  public onInitialize(engine: Engine<any>): void {
    const viewport = engine.screen.getScreenBounds();
    let scale = Config.scale;

    const spritesheet = SpriteSheet.fromImageSource({
      image: Resources.Leaf,
      grid: {
        rows: 5,
        columns: 6,
        spriteWidth: 12,
        spriteHeight: 12,
      },
    });
    let anim = Animation.fromSpriteSheet(spritesheet, range(0, 29), 100);
    anim.scale = vec(scale, scale);

    var emitter = new ParticleEmitter({ x: 0, y: -64, width: viewport.width, height: 64 });
    emitter.particleSprite = anim;
    emitter.emitterType = EmitterType.Rectangle;
    emitter.radius = 28;
    emitter.minVel = 15;
    emitter.maxVel = 34;
    emitter.minAngle = 0;
    emitter.maxAngle = 6.2;
    emitter.isEmitting = true;
    emitter.emitRate = 1;
    emitter.opacity = 1.0;
    emitter.fadeFlag = true;
    emitter.particleLife = 10000;
    emitter.maxSize = 10;
    emitter.minSize = 1;
    emitter.startSize = 0;
    emitter.endSize = 0;
    emitter.acceleration = new Vector(25, 49);
    emitter.beginColor = Color.White;
    emitter.endColor = Color.Transparent;

    this.scene?.add(emitter);
  }
}