import { ErrorFallback } from "@/components/ErrorFallback.tsx";
import { ErrorBoundary, Suspense } from "solid-js/web";
import { SDKProvider } from "@tma.js/sdk-solid";
import { GameProvider } from "@/components/GameProvider.tsx";
import { Route, Router } from "@solidjs/router";
import { GameBootstrapRoute } from "@/routes/GameBootstrapRoute.tsx";
import { GameLayout } from "@/components/GameLayout.tsx";
import { DebugRoute } from "@/routes/DebugRoute.tsx";
import { createEffect, lazy, onCleanup, onMount } from "solid-js";
import { useConnectionEvent } from "@/components/connection.ts";
import { QueryClient, QueryClientProvider } from "@tanstack/solid-query";
import { TonWalletProvider } from "@/components/TonConnectUI.tsx";
import { showToast, Toaster } from "@/components/ui/toast.tsx";
import Loading from "./components/Loading";
import UpgradesRoute from "./routes/UpgradesRoute";
import FriendsRoute from "./routes/FriendsRoute";
import QuestsRoute from "./routes/QuestsRoute";
import AirdropRoute from "./routes/AirdropRoute";
import * as R from "remeda";

const OnboardingRoute = lazy(() => import("@/components/OnboardingRoute.tsx"));
import CloseRoute from "@/routes/CloseRoute";
import ShopRoute from "./routes/ShopRoute";
import { IS_RANCH_PROD } from "./config";
import SettingsRoute from "./routes/SettingsRoute";
import { settingsStoreActions } from "./components/settings-store";
import { DebugThrowErrorRoute } from "@/routes/DebugThrowErrorRoute.tsx";
import MatchRoute from "./routes/MatchRoute";
import { RaffleMachineRoute } from "@/routes/RaffleMachineRoute.tsx";

const queryClient = new QueryClient();

export const SuspenseFallback = () => {
  return <Loading />;
};

const startBgMusicOnce = R.once(settingsStoreActions.enableBgMusic);

const stopStartMusicOnVisibilityChange = () => {
  function handleVisibilityChange() {
    if (document.hidden) {
      settingsStoreActions.disableBgMusic();
    } else {
      settingsStoreActions.enableBgMusic();
    }
  }

  onMount(() => {
    createEffect(() => {
      document.addEventListener("visibilitychange", handleVisibilityChange);
    });
  });

  onCleanup(() => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  });
};

export function App() {
  stopStartMusicOnVisibilityChange();
  useConnectionEvent("PlayerReferred", (referee: string) => {
    showToast({
      title: `${referee} joined your referrals! 🎉`,
    });
  });

  useConnectionEvent("RealtimeNotification", (message: string) => {
    showToast({ title: message });
  });

  return (
    <>
      <ErrorBoundary fallback={(err) => <ErrorFallback error={err} />}>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<SuspenseFallback />}>
            <SDKProvider acceptCustomStyles debug>
              <TonWalletProvider>
                <div onClick={startBgMusicOnce} class="h-[100vh]">
                  <GameProvider>
                    <Router preload={false}>
                      <Route path="/" component={GameBootstrapRoute} />
                      <Route path="/onboarding" component={OnboardingRoute} />
                      <Route
                        path="/raffle-machine"
                        component={RaffleMachineRoute}
                      />
                      <Route path="/play" component={GameLayout}>
                        <Route path="/" />
                        <Suspense fallback={<div>i hate this</div>}>
                          <Route path="/ranch" component={UpgradesRoute} />
                        </Suspense>
                        {!IS_RANCH_PROD && (
                          <>
                            <Route path="/debug" component={DebugRoute} />
                            <Route
                              path="/throw-error"
                              component={DebugThrowErrorRoute}
                            />
                          </>
                        )}
                        <Route path="/friends" component={FriendsRoute} />
                        <Route path="/quests" component={QuestsRoute} />
                        <Route path="/airdrop" component={AirdropRoute} />
                        <Route path="/shop" component={ShopRoute} />
                        <Route path="/settings" component={SettingsRoute} />
                        <Route path="/match" component={MatchRoute} />
                      </Route>
                      <Route path="/close" component={CloseRoute} />
                    </Router>
                  </GameProvider>
                </div>
              </TonWalletProvider>
            </SDKProvider>
          </Suspense>
        </QueryClientProvider>
      </ErrorBoundary>
      <Toaster />
    </>
  );
}
