import { default as chickCoopImage } from "./chick-coop.jpg";
import { default as bitgetImage } from "./bitget.png";
import { default as bitgetGlowImage } from "./bitget-glow.png";

// These needs to be the IconId from the CustomQuests
export const CUSTOM_QUESTS_IMAGES = {
  ChickCoop: chickCoopImage,
  Bitget: bitgetImage,
  BitgetGlow: bitgetGlowImage,
};
