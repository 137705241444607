import {
  Engine,
  Actor,
  Random,
  vec,
  Color,
  MoveBy,
  Fade,
  ParallelActions,
  Animation,
  Vector,
  BoundingBox,
  ParticleEmitter,
  EmitterType,
  Timer,
  SpriteSheet,
  range,
  AnimationStrategy,
  EasingFunctions,
  coroutine,
} from "excalibur";

import { Resources, BeastResources } from "../resources";
import { Config } from "../config";
import { playerStore, playerStoreActions } from "@/components/player-store.ts";
import { FloatText } from "@/game/actors/float-text";
import { vibrate } from "@/lib/utils.ts";
import { createEffect } from "solid-js";
import { HatsConfig } from "../hats-config";
import { animManager } from "./animation-manager";
import { flash } from "../materials/flash";
import { eventBus } from "@/components/event-bus";

enum Happiness {
  VerySad,
  Sad,
  Happy,
  VeryHappy,
}

const hatAnchors: { [id: number]: Vector } = {
  211: vec(18, 19),
  215: vec(15, 11),
  219: vec(13, 16),
}

export class Beast extends Actor {
  public identifier: number = 211;
  public sort: boolean = true;
  public happiness?: Happiness;
  public scaleFactor = 1;

  private shadow: Actor = new Actor({ name: "beast-shadow" });
  private hat: Actor = new Actor({ name: "beast-hat" });
  private halo: Actor = new Actor({ name: "beast-halo" });
  private random: Random;
  private flashMaterial: ex.Material | null = null;
  private isTransforming: boolean = false;
  private viewport: BoundingBox = new BoundingBox();
  private burstEmitter?: ParticleEmitter;
  private sparkleAnim?: Animation;

  constructor(id: number) {
    super({ x: 0, y: 0 });

    this.random = new Random();
    this.identifier = id;

    this.pointer.useGraphicsBounds = true;
    this.on("pointerdown", async () => {
      if (this.isTransforming) return;
      let result = await playerStoreActions.handleFeed();
      let scale = Config.scale;
      this.actions.clearActions();
      this.scale = vec(scale, scale);
      this.actions
        .scaleBy(vec(-0.25, 0.5), 18)
        .scaleBy(vec(0.25, -0.5), 18)
        .scaleBy(vec(0.5, -0.25), 12)
        .scaleBy(vec(-0.5, 0.25), 12);
      vibrate("light");

      let text = result ? "+1" : "No shards!";
      let color = result ? Color.Green : Color.fromRGB(255, 64, 64);
      let font = result ? "Eazy Chat" : "Super Bubble";
      this.scene?.add(
        new FloatText(
          this.pos.x + this.random.integer(-32, 32),
          this.pos.y - 180,
          text,
          32,
          color,
          font,
        ),
      );
    });

    eventBus.on("luckySerumClaimed", () => {
      console.log(`[Event luckySerumClaimed] converting to lucky`);
      this.convertToLucky();
    });

    eventBus.on("reincarnate", () => {
      console.log(`[Event reincarnate] reincarnating...`);
      this.reincarnate();
    })
  }

  public onInitialize(engine: Engine<any>): void {
    this.viewport = engine.screen.getScreenBounds();
    let scale = Config.scale;
    this.scaleFactor = scale;

    this.pos.x = this.viewport.width / 2;
    this.pos.y = this.viewport.height - 75 * scale;
    this.name = "beast";
    this.anchor = vec(0.5, 1);

    this.graphics.use(this.getBeastAnim());
    this.scale = vec(scale, scale);

    this.shadow.graphics.use(Resources.BeastShadow.toSprite());
    this.shadow.pos = vec(this.pos.x, this.pos.y - 4 * scale);
    this.shadow.graphics.opacity = 0.5;
    this.shadow.scale = vec(scale, scale);
    this.scene?.add(this.shadow);

    this.hat.pos = vec(-16, -32).add(hatAnchors[this.identifier]);
    this.addChild(this.hat);
    this.scene?.add(this.hat);
    this.halo.pos = vec(-16, -32).add(hatAnchors[this.identifier]).add(vec(0, -2));
    this.addChild(this.halo);
    this.scene?.add(this.halo);

    const flashMaterial = engine.graphicsContext.createMaterial({
      name: "flash",
      fragmentSource: flash,
    });
    flashMaterial.update(shader => {
      shader.trySetUniformFloat('weight', 1.0);
    });
    this.flashMaterial = flashMaterial;

    createEffect(() => {
      this.identifier = playerStore.beast.specieStageId;
      this.hat.pos = vec(-16, -32).add(hatAnchors[this.identifier]);
      this.halo.pos = vec(-16, -32).add(hatAnchors[this.identifier]).add(vec(0, -2));
      if (!this.isTransforming) {
        if (playerStore.beast.isLucky) {
          this.sparkleAnim = BeastResources["sparkle-gold"];
          this.sparkleAnim!.scale = vec(this.scaleFactor, this.scaleFactor);
          animManager.play(this.sparkleAnim!, this.pos.sub(vec(0, 14 * this.scaleFactor)));
        } else {
          this.graphics.use(this.getBeastAnim());
        }
      }
    });

    createEffect(() => {
      if (playerStore.beast.equippedHatId) {
        this.equipHat(playerStore.beast.equippedHatId);
      }
      else {
        this.unequipHat();
      }
    });

    createEffect(() => {
      if (this.isTransforming === false) {
        if (playerStore.beast.reincarnations > 0) {
          this.addHalo();
        } else {
          this.removeHalo();
        }
      }
    })

    const spritesheet = SpriteSheet.fromImageSource({
      image: Resources.Sparkle,
      grid: {
        rows: 1,
        columns: 16,
        spriteWidth: 7,
        spriteHeight: 7,
      },
    });
    let anim = Animation.fromSpriteSheet(spritesheet, range(0, 15), 100);
    anim.strategy = AnimationStrategy.Loop;
    anim.scale = vec(scale, scale);
    var emitter = new ParticleEmitter({
      x: this.pos.x,
      y: this.pos.y - 16 * scale,
      width: 16 * scale,
      height: 16 * scale,
    });
    emitter.particleSprite = anim;
    emitter.emitterType = EmitterType.Circle;
    emitter.radius = 32;
    emitter.minVel = 100;
    emitter.maxVel = 200;
    emitter.minAngle = 0;
    emitter.maxAngle = 6.2;
    emitter.isEmitting = false;
    emitter.emitRate = 150;
    emitter.opacity = 1;
    emitter.fadeFlag = true;
    emitter.particleLife = 500;
    emitter.maxSize = 28;
    emitter.minSize = 2;
    emitter.startSize = 4;
    emitter.endSize = 0;
    emitter.acceleration = new Vector(0, 400);
    emitter.beginColor = Color.White;
    emitter.endColor = Color.Transparent;
    //emitter.scale = vec(scale, scale);
    emitter.z = this.viewport.height + 3;
    this.burstEmitter = emitter;
    this.scene?.add(this.burstEmitter);
  }

  public update(engine: ex.Engine, delta: number) {
    super.update(engine, delta);
    if (this.sort) {
      this.z = this.pos.y;
      this.hat.z = this.pos.y + 1;
      this.halo.z = this.pos.y + 1;
      if (this.isTransforming) {
        this.z = this.viewport.height + 1;
        this.hat.z = this.viewport.height + 2;
        this.halo.z = this.viewport.height + 2;
      }
    }
  }

  public convertToLucky() {
    let overlay = new Actor({
      x: 0,
      y: 0,
      width: this.viewport.width * this.scaleFactor,
      height: this.viewport.height * this.scaleFactor,
      color: Color.Black,
      opacity: 0,
    });
    overlay.z = this.viewport.height;
    this.scene?.add(overlay);
    this.isTransforming = true;

    overlay.actions.fade(0.8, 300);

    const _changeAnim = () => {
      this.graphics.use(this.getBeastAnim());
      this.sparkleAnim = BeastResources["sparkle-gold"];
      this.sparkleAnim!.scale = vec(this.scaleFactor, this.scaleFactor);
      animManager.play(this.sparkleAnim!, this.pos.sub(vec(0, 14 * this.scaleFactor)));
      this.scene?.camera.shake(5, 5, 100);
      vibrate("heavy");
    }

    let scale = 0.1;
    this.actions.repeat((repeatCtx) => {
      repeatCtx
        .delay(500)
        .callMethod(() => {
          this.graphics.material = this.flashMaterial;
          scale += 0.1;
        })
        /* will polish this later
        .callMethod(() => {
          this.burstEmitter!.isEmitting = true;
          const timer = new Timer({
            fcn: () => {
              this.burstEmitter!.isEmitting = false;
            },
            repeats: false,
            interval: 100,
          });
          this.scene?.add(timer);
          timer.start();
        })
          */
        .scaleBy(vec(scale, scale), 8)
        .delay(100)
        .scaleBy(vec(-scale, -scale), 8)
        .callMethod(() => {
          this.graphics.material = null;
        })
        .delay(1000)
    }, 3)
      .callMethod(() => {
        this.graphics.material = this.flashMaterial;
      })
      .scaleBy(vec(0.5, 0.5), 8)
      /*
      .callMethod(() => {
        this.burstEmitter!.isEmitting = true;
        const timer = new Timer({
          fcn: () => {
            this.burstEmitter!.isEmitting = false;
          },
          repeats: false,
          interval: 100,
        });
        this.scene?.add(timer);
        timer.start();
      })
        */
      .delay(100)
      .scaleBy(vec(-0.5, -0.5), 8)
      .callMethod(_changeAnim)
      .delay(100)
      .callMethod(() => {
        this.graphics.material = null;
        this.isTransforming = false;
        overlay.actions.fade(0.0, 300);
      })
  }

  public showHappinessEmote(happiness: number) {
    console.log(`happiness: ${happiness}`);
    let image = Resources.VeryHappy;
    let currenthappiness = Happiness.VeryHappy;
    if (happiness <= 25) {
      image = Resources.VerySad;
      currenthappiness = Happiness.VerySad;
    } else if (happiness <= 50) {
      image = Resources.Sad;
      currenthappiness = Happiness.Sad;
    } else if (happiness <= 99) {
      image = Resources.Happy;
      currenthappiness = Happiness.Happy;
    }
    if (currenthappiness == this.happiness) {
      return;
    }

    this.happiness = currenthappiness;
    let emote = new Actor({
      x: this.pos.x,
      y: this.pos.y - 24 * Config.scale,
      anchor: vec(0.5, 1),
    });
    emote.graphics.use(image.toSprite());
    emote.scale = vec((1 / Config.scale) * 1.5, (1 / Config.scale) * 1.5);
    this.scene?.add(emote);
    let actions = [
      new MoveBy(emote, 0, -8 * Config.scale, 8 * Config.scale * 7),
      new Fade(emote, 1, 300),
    ];
    const parallel = new ParallelActions(actions);
    emote.actions
      .runAction(parallel)
      .scaleBy(vec(0.05, 0.05), 1)
      .scaleBy(vec(-0.05, -0.05), 1)
      .delay(1000)
      .fade(0, 600)
      .die();
  }

  public equipHat(hatId: string) {
    console.log(`Equipped a Beast Hat with id: ${hatId}`);
    const hat = HatsConfig[hatId];
    this.hat.graphics.use(hat.src.toSprite());
    this.hat.anchor = vec(hat.originX / hat.src.width, hat.originY / hat.src.height);
  }

  public unequipHat() {
    this.hat.graphics.remove('default');
  }

  public addHalo() {
    const halo = HatsConfig["halo"];
    this.halo.graphics.use(halo.src.toSprite());
    this.halo.anchor = vec(halo.originX / halo.src.width, halo.originY / halo.src.height);
  }

  public removeHalo() {
    this.halo.graphics.remove('default');
  }

  private getBeastAnim() {
    let animName = `${this.identifier}-idle`;
    if (playerStore.beast.isLucky) {
      animName = `${this.identifier}-idle-lucky`;
    }
    const anim: Animation = BeastResources[animName];
    anim.events.on('frame', (frame) => {
      let offsetY = frame.frameIndex % 2;
      this.hat.offset = vec(0, offsetY / this.scaleFactor);
      this.halo.offset = vec(0, offsetY / this.scaleFactor);
    });
    return anim;
  }

  private reincarnate() {
    let overlay = new Actor({
      x: 0,
      y: 0,
      width: this.viewport.width * this.scaleFactor,
      height: this.viewport.height * this.scaleFactor,
      color: Color.Black,
      opacity: 0,
    });
    overlay.z = this.viewport.height;
    this.scene?.add(overlay);
    this.isTransforming = true;

    overlay.actions.fade(0.8, 300);

    let reincarnateWings = new Actor({
      x: 0,
      y: 0,
      z: 1000,
    });
    let spritesheet = SpriteSheet.fromImageSource({
      image: Resources.ReincarnateWings,
      grid: {
        rows: 5,
        columns: 8,
        spriteWidth: 96,
        spriteHeight: 96,
      },
    });
    let anim = Animation.fromSpriteSheet(spritesheet, range(0, 33), 100);
    anim.strategy = AnimationStrategy.End;
    reincarnateWings.anchor = vec(0.5, 2 / 3);
    reincarnateWings.graphics.use(anim);

    let reincarnateHalo = new Actor({
      x: 0,
      y: 0,
      z: 1000,
    });
    spritesheet = SpriteSheet.fromImageSource({
      image: Resources.ReincarnateHalo,
      grid: {
        rows: 5,
        columns: 8,
        spriteWidth: 13,
        spriteHeight: 41,
      },
    });
    anim = Animation.fromSpriteSheet(spritesheet, range(0, 32), 100);
    anim.strategy = AnimationStrategy.End;
    anim.events.on("end", () => {
      this.addHalo();
      this.isTransforming = false;
    })
    reincarnateHalo.pos = vec(-16, -32).add(hatAnchors[this.identifier]).add(vec(0, -8));
    reincarnateHalo.anchor = vec(0.5, 1);
    reincarnateHalo.graphics.use(anim);

    let self = this;
    function* fadeOut() {
      const startTime = self.scene!.engine.clock.now();
      let value = 0;

      while (value < 1) {
        const currentTime = self.scene!.engine.clock.now();
        const elapsedTime = currentTime - startTime;
        value = Math.min(elapsedTime / 360, 1);
        self.flashMaterial!.update(shader => {
          shader.trySetUniformFloat('weight', value);
        });
        yield;
      }
    }
    function* fadeIn() {
      const startTime = self.scene!.engine.clock.now();
      let value = 1;

      while (value > 0) {
        const currentTime = self.scene!.engine.clock.now();
        const elapsedTime = currentTime - startTime;
        value = Math.max(1 - elapsedTime / 360, 0);
        self.flashMaterial!.update(shader => {
          shader.trySetUniformFloat('weight', value);
        });
        yield;
      }
    }

    this.actions.delay(500)
      .callMethod(() => {
        this.addChild(reincarnateWings);
        this.addChild(reincarnateHalo);
        this.graphics.material = this.flashMaterial;
        this.hat.graphics.material = this.flashMaterial;
        coroutine(this.scene?.engine, fadeOut);
      })
      .easeBy(vec(0, -6 * Config.scale), 360, EasingFunctions.EaseInOutCubic)
      .delay(900)
      .callMethod(() => {
        coroutine(this.scene?.engine, fadeIn);
      })
      .easeBy(vec(0, 6 * Config.scale), 360, EasingFunctions.EaseInOutCubic)
      .delay(60)
      .callMethod(() => {
        this.graphics.material = null;
        this.hat.graphics.material = null;
        overlay.actions.fade(0.0, 300);
      });
  }
}
