import {
  playerStore,
  refreshPlayerState,
  setPlayerStore,
} from "@/components/player-store.ts";
import { Button } from "@/components/ui/button.tsx";
import {
  createEffect,
  createSignal,
  For,
  Match,
  onCleanup,
  Show,
  Switch,
} from "solid-js";
import { Card, CardContent } from "@/components/ui/card.tsx";
import {
  createMutation,
  createQuery,
  useQueryClient,
} from "@tanstack/solid-query";
import {
  discount33,
  discount40,
  discount50,
  discount60,
  limitedEditionLeft,
  limitedEditionRight,
  rewardBg,
  upgradeStarImg,
} from "@/assets/images.ts";
import { PageContainer } from "@/components/PageContainer";
import { useIsRouting, useNavigate } from "@solidjs/router";
import { Suspense } from "solid-js/web";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { playSfxSound, sounds } from "@/components/sounds";
import "../components/upgrades.css";
import { CloseButton } from "@/components/CloseButton";
import { WebApp } from "@/twa-sdk-updated/core";
import { SHOP_IMAGES } from "@/assets/shop/shop-images";
import connection from "@/components/connection";
import { showToast } from "@/components/ui/toast";
import { captureEvent } from "@/lib/analytics";
import { cn } from "@/lib/utils";
import { LockIcon } from "@/assets/extra-icons";
import { InventoryService, PlayerShopListing, ShopService } from "@/api-client";
import { eventBus } from "@/components/event-bus";
import { sortBy } from "remeda";

type PriceItem = {
  originalPrice: string;
  discountPrice?: string;
  limitedEdition?: boolean;
  discountBanner?: string;
};

export const ITEM_PRICES: {
  [key: string]: PriceItem;
} = {
  eggshell: {
    originalPrice: "4.99",
    discountPrice: "1.99",
    discountBanner: discount60,
  },
  acorn: {
    originalPrice: "4.99",
    discountPrice: "2.99",
    discountBanner: discount40,
  },
  leaf: {
    originalPrice: "4.99",
    discountPrice: "2.99",
    discountBanner: discount40,
  },
  straw: {
    originalPrice: "4.99",
  },
  witch: {
    originalPrice: "4.99",
  },
  boc: {
    originalPrice: "14.99",
    discountPrice: "9.99",
    limitedEdition: true,
    discountBanner: discount33,
  },
  "lucky-serum": {
    originalPrice: "13.99",
    discountPrice: "6.99",
    limitedEdition: true,
    discountBanner: discount50,
  },
};

function ShopItemCardLocked(props: {
  item: PlayerShopListing;
  shopListingRefetch: () => void;
}) {
  // Better not show the locked items
  return null;

  return (
    <div class="relative flex flex-1 p-2" style={{ width: "100%" }}>
      {/* <Show when={ITEM_PRICES[props.item.id].discountPrice}>
        <div class="pointer-events-none absolute right-0 top-0 z-10 w-[50%]">
          <img
            src={ITEM_PRICES[props.item.id].discountBanner}
            class="object-contain"
          />
        </div>
      </Show> */}
      {/* <Show when={ITEM_PRICES[props.item.id].limitedEdition}>
        <div class="pointer-events-none absolute -right-[0.45rem] -top-[0.45rem] z-10 w-[45%]">
          <img src={limitedEditionRight} class="object-contain" />
        </div>
      </Show> */}
      <Card
        class="relative flex flex-1 border bg-cover bg-center bg-no-repeat text-white"
        style={{
          "box-shadow": "0px 2px 4px 0px #740F0F40",
          "border-color": props.item.isPurchased
            ? "#258D49"
            : ITEM_PRICES[props.item.id].limitedEdition
              ? "#F2935E"
              : "#E59191",
          "background-color": props.item.isPurchased
            ? "#43B64F"
            : ITEM_PRICES[props.item.id].limitedEdition
              ? "#FFBA68"
              : "#F6B9B0",
        }}
      >
        <div
          class="absolute bottom-0 left-0 right-0 top-0 z-[5] min-h-full min-w-full bg-cover bg-center bg-no-repeat"
          style={{
            "background-image": `url(${rewardBg})`,
          }}
        />
        <CardContent class="z-10 flex h-full w-full flex-col justify-between gap-2 p-2">
          <div class="flex flex-col">
            <div class="flex items-center justify-center">
              <img
                class="z-10 h-14 w-14 object-contain"
                style={{
                  "image-rendering": "pixelated",
                }}
                src={SHOP_IMAGES[props.item.id as keyof typeof SHOP_IMAGES]}
              />
            </div>
            <div class="z-10 flex flex-col items-center justify-center gap-1">
              <h3 class="eazy-chat text-outline z-10 text-lg font-medium leading-5">
                {props.item.name}
              </h3>
            </div>
          </div>
          <Button
            disabled={props.item.locked}
            size="sm"
            class={cn(
              "eazy-chat text-outline relative z-10 min-w-fit border border-[#8D1D1D] bg-gradient-to-b from-[#DC5555] to-[#CA3E3C] text-lg leading-4 hover:opacity-70",
              ITEM_PRICES[props.item.id].limitedEdition &&
                "from-[#FCB74F] to-[#FA9044]",
            )}
          >
            <div class="rounded-md"></div>
            <span class="z-10">
              <div class="flex items-center gap-2">
                <div class="flex items-center justify-center gap-1">
                  <Show when={props.item.locked}>
                    <LockIcon />
                  </Show>
                  <Show when={ITEM_PRICES[props.item.id].discountPrice}>
                    <span class="mt-1 text-sm line-through">
                      ${ITEM_PRICES[props.item.id].originalPrice}
                    </span>
                  </Show>
                  <span class="mt-1">
                    $
                    {ITEM_PRICES[props.item.id].discountPrice ??
                      ITEM_PRICES[props.item.id].originalPrice}
                  </span>
                </div>
              </div>
            </span>
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}

function ShopItemCardClaimed(props: {
  item: PlayerShopListing;
  shopListingRefetch: () => void;
}) {
  return null;
  // return (
  //   <div class="relative flex flex-1 p-2" style={{ width: "100%" }}>
  //     <Show when={ITEM_PRICES[props.item.id].discountPrice}>
  //       <div class="pointer-events-none absolute right-0 top-0 z-10 w-[50%]">
  //         <img
  //           src={ITEM_PRICES[props.item.id].discountBanner}
  //           class="object-contain"
  //         />
  //       </div>
  //     </Show>
  //     <Show when={ITEM_PRICES[props.item.id].limitedEdition}>
  //       <div class="pointer-events-none absolute -right-[0.45rem] -top-[0.45rem] z-10 w-[45%]">
  //         <img src={limitedEditionRight} class="object-contain" />
  //       </div>
  //     </Show>
  //     <Card
  //       class="relative flex flex-1 border bg-cover bg-center bg-no-repeat text-white"
  //       style={{
  //         "box-shadow": "0px 2px 4px 0px #740F0F40",
  //         "border-color": "#258D49",
  //         "background-color": "#43B64F",
  //       }}
  //     >
  //       <div
  //         class="absolute bottom-0 left-0 right-0 top-0 z-[5] min-h-full min-w-full bg-cover bg-center bg-no-repeat"
  //         style={{
  //           "background-image": `url(${rewardBg})`,
  //         }}
  //       />
  //       <CardContent class="z-10 flex h-full w-full flex-col justify-between gap-2 p-2">
  //         <div class="flex flex-col">
  //           <div class="flex items-center justify-center">
  //             <img
  //               class="z-10 h-14 w-14 object-contain"
  //               style={{
  //                 "image-rendering": "pixelated",
  //               }}
  //               src={SHOP_IMAGES[props.item.id as keyof typeof SHOP_IMAGES]}
  //             />
  //           </div>
  //           <div class="z-10 flex flex-col items-center justify-center gap-1">
  //             <h3 class="eazy-chat text-outline z-10 text-lg font-medium leading-5">
  //               {props.item.name}
  //             </h3>
  //           </div>
  //         </div>
  //         <Button
  //           disabled={true}
  //           size="sm"
  //           class={cn(
  //             "eazy-chat text-outline relative z-10 min-w-fit border border-[#095528] bg-gradient-to-b from-[#258D49] to-[#187445] text-lg leading-4 hover:opacity-70 disabled:opacity-100",
  //           )}
  //         >
  //           <span class="z-10">Claimed</span>
  //         </Button>
  //       </CardContent>
  //     </Card>
  //   </div>
  // );
}

function ShopItemCard(props: {
  item: PlayerShopListing;
  shopListingRefetch: () => void;
}) {
  const [isAnimating, setIsAnimating] = createSignal(false);
  const navigate = useNavigate();

  const startAnimation = () => {
    console.log("THIS IS NEW!!");
    setIsAnimating(true);
    const timer = setTimeout(() => {
      setIsAnimating(false);
      clearTimeout(timer);
    }, 2000);
  };

  const queryClient = useQueryClient();
  const purchaseMutation = createMutation(() => ({
    mutationFn: () => {
      captureEvent("purchase_item_intention", {
        itemId: props.item.id,
        itemName: props.item.name,
        itemCost: props.item.starsCost,
      });
      return ShopService.initiatePurchase({
        listingId: props.item.id,
      });
    },
    onSuccess: (result) => {
      console.log("DATA", result);
      WebApp.openInvoice(result.invoiceLink, (cb) => {
        // This callbacks doesn't seem to work btw
        // Using the ItemPurchased event instead
        console.log("INVOICE CALLBACK", cb);
        props.shopListingRefetch();
        refreshPlayerState();
        startAnimation();
        playSfxSound("purchase");
        queryClient.setQueryData(
          ["shopListingsQuery"],
          (data: PlayerShopListing[]) =>
            data.map((sl) =>
              sl.id === props.item.id
                ? {
                    ...props.item,
                    isPurchased: true,
                  }
                : sl,
            ),
        );
      });
    },
  }));

  createEffect(() => {
    if (connection()) {
      connection()?.on("ItemPurchased", (listingsId: string[]) => {
        if (listingsId.includes(props.item.id)) {
          props.shopListingRefetch();
          refreshPlayerState();
          startAnimation();
          playSfxSound("purchase");
          captureEvent("purchase_item_success", {
            itemId: props.item.id,
            itemName: props.item.name,
            itemCost: props.item.starsCost,
          });
          if (props.item.itemType === "BeastHat") {
            captureEvent("equip_item", {
              itemId: props.item.id,
              itemName: props.item.name,
            });
          }
        }
      });
    }
  });

  const equipMutation = createMutation(() => ({
    mutationFn: () => {
      return InventoryService.equipItem({
        requestBody: {
          itemId: props.item.id,
        },
      });
    },
    onSuccess: (result) => {
      console.log("DATA", result);
      refreshPlayerState();
      setPlayerStore("beast", {
        equippedHatId: props.item.id,
      });
      captureEvent("equip_item", {
        itemId: props.item.id,
        itemName: props.item.name,
      });
    },
  }));

  const unequipMutation = createMutation(() => ({
    mutationFn: () => {
      return InventoryService.unequipHat();
    },
    onSuccess: (result) => {
      console.log("DATA", result);
      refreshPlayerState();
      setPlayerStore("beast", {
        equippedHatId: undefined,
      });
      captureEvent("unequip_item", {
        itemId: props.item.id,
        itemName: props.item.name,
      });
    },
  }));

  const useItemMutation = createMutation(() => ({
    mutationFn: () => {
      return InventoryService.useItem({
        requestBody: {
          itemId: props.item.id,
        },
      });
    },
    onSuccess: (result) => {
      captureEvent("lucky_claimed");
      refreshPlayerState();
      navigate("/play");
      eventBus.emit("luckySerumClaimed");
    },
  }));

  const buttonText = () => {
    if (purchaseMutation.isPending) {
      return "Buying...";
    } else {
      return (
        <div class="flex items-center gap-2">
          <div class="flex items-center justify-center gap-1">
            <Show when={props.item.locked}>
              <LockIcon />
            </Show>
            <Show when={ITEM_PRICES[props.item.id].discountPrice}>
              <span class="mt-1 text-sm line-through">
                ${ITEM_PRICES[props.item.id].originalPrice}
              </span>
            </Show>
            <span class="mt-1">
              $
              {ITEM_PRICES[props.item.id].discountPrice ??
                ITEM_PRICES[props.item.id].originalPrice}
            </span>
          </div>
        </div>
      );
    }
  };

  return (
    <Switch
      fallback={
        <div class="relative flex flex-1 p-2" style={{ width: "100%" }}>
          <Show
            when={
              ITEM_PRICES[props.item.id].discountPrice && !props.item.locked
            }
          >
            <div class="pointer-events-none absolute right-0 top-0 z-10 w-[50%]">
              <img
                src={ITEM_PRICES[props.item.id].discountBanner}
                class="object-contain"
              />
            </div>
          </Show>
          <Show
            when={
              ITEM_PRICES[props.item.id].limitedEdition && !props.item.locked
            }
          >
            <div class="pointer-events-none absolute -right-[0.45rem] -top-[0.45rem] z-10 w-[45%]">
              <img src={limitedEditionRight} class="object-contain" />
            </div>
          </Show>
          <Card
            class="relative flex flex-1 border bg-cover bg-center bg-no-repeat text-white"
            style={{
              "box-shadow": "0px 2px 4px 0px #740F0F40",
              "border-color": props.item.isPurchased
                ? "#258D49"
                : ITEM_PRICES[props.item.id].limitedEdition
                  ? "#F2935E"
                  : "#E59191",
              "background-color": props.item.isPurchased
                ? "#43B64F"
                : ITEM_PRICES[props.item.id].limitedEdition
                  ? "#FFBA68"
                  : "#F6B9B0",
            }}
          >
            <div class={`${isAnimating() ? "green" : ""}`} style={{}}></div>
            <div
              class="absolute bottom-0 left-0 right-0 top-0 z-[5] min-h-full min-w-full bg-cover bg-center bg-no-repeat"
              style={{
                "background-image": `url(${rewardBg})`,
              }}
            />
            <CardContent class="z-10 flex h-full w-full flex-col justify-between gap-2 p-2">
              <div class="flex flex-col">
                <div class="flex items-center justify-center">
                  <img
                    class="z-10 h-14 w-14 object-contain"
                    style={{
                      "image-rendering": "pixelated",
                    }}
                    src={SHOP_IMAGES[props.item.id as keyof typeof SHOP_IMAGES]}
                  />
                </div>
                <div class="z-10 flex flex-col items-center justify-center gap-1">
                  <h3 class="eazy-chat text-outline z-10 text-lg font-medium leading-5">
                    {props.item.name}
                  </h3>
                </div>
              </div>
              <Switch>
                <Match when={props.item.isPurchased}>
                  <Switch>
                    <Match when={props.item.itemType === "BeastHat"}>
                      <Button
                        onClick={() => {
                          if (
                            playerStore.beast.equippedHatId != props.item.id
                          ) {
                            equipMutation.mutate();
                          } else {
                            unequipMutation.mutate();
                          }
                        }}
                        disabled={
                          equipMutation.isPending || unequipMutation.isPending
                        }
                        size="sm"
                        class="eazy-chat text-outline relative z-10 min-w-fit border border-[#095528] bg-gradient-to-b from-[#258D49] to-[#187445] text-lg leading-4 hover:opacity-70"
                      >
                        <span class="z-10">
                          {playerStore.beast.equippedHatId != props.item.id
                            ? "Equip"
                            : "Unequip"}
                        </span>
                      </Button>
                    </Match>
                    <Match when={props.item.itemType === "Consumable"}>
                      <Button
                        onClick={() => {
                          if (!playerStore.beast.isLucky) {
                            useItemMutation.mutate();
                          }
                        }}
                        disabled={
                          !playerStore.beast.isLucky
                            ? useItemMutation.isPending
                            : true
                        }
                        size="sm"
                        class={cn(
                          "eazy-chat text-outline relative z-10 min-w-fit border border-[#095528] bg-gradient-to-b from-[#258D49] to-[#187445] text-lg leading-4 hover:opacity-70 disabled:opacity-100",
                        )}
                      >
                        <span class="z-10">
                          {!playerStore.beast.isLucky ? "Claim" : "Claimed"}
                        </span>
                      </Button>
                    </Match>
                  </Switch>
                </Match>
                <Match when={!props.item.isPurchased}>
                  <Button
                    onClick={() => {
                      purchaseMutation.mutate();
                    }}
                    disabled={props.item.locked || purchaseMutation.isPending}
                    size="sm"
                    class={cn(
                      "eazy-chat text-outline relative z-10 min-w-fit border border-[#8D1D1D] bg-gradient-to-b from-[#DC5555] to-[#CA3E3C] text-lg leading-4 hover:opacity-70",
                      ITEM_PRICES[props.item.id].limitedEdition &&
                        "from-[#FCB74F] to-[#FA9044]",
                    )}
                  >
                    <div
                      class="rounded-md"
                      classList={{ "button-green": isAnimating() }}
                    ></div>
                    <span class="z-10">{buttonText()} </span>
                  </Button>
                </Match>
              </Switch>
            </CardContent>
          </Card>
        </div>
      }
    >
      <Match
        when={props.item.id === "lucky-serum" && playerStore.beast.isLucky}
      >
        <ShopItemCardClaimed
          item={props.item}
          shopListingRefetch={props.shopListingRefetch}
        />
      </Match>
      <Match when={props.item.locked && !props.item.isPurchased}>
        <ShopItemCardLocked
          item={props.item}
          shopListingRefetch={props.shopListingRefetch}
        />
      </Match>
    </Switch>
  );
}

function sortShopListings(listings: PlayerShopListing[]) {
  return sortBy(listings, (listing) => listing.id !== "lucky-serum");
}

export default function ShopRoute() {
  const shopListingsQuery = createQuery(() => ({
    queryKey: ["shopListingsQuery"],
    queryFn: async () => {
      const listing = await ShopService.getShopListings();
      return sortShopListings(listing);
    },
  }));
  console.log("SHOP LISTINGS", shopListingsQuery.data);
  const isRouting = useIsRouting();

  const shopListingRefetch = () => shopListingsQuery.refetch();

  return (
    <PageContainer>
      <div class="relative flex h-full w-full flex-col">
        <div class="sticky top-0 z-10 flex flex-col items-center justify-center gap-2 border border-[#561212] bg-gradient-to-b from-[#DC5555] to-[#CA3E3C] px-3 py-4 text-center">
          <CloseButton />
          <h2 class="eazy-chat text-outline-1 mt-1 text-4xl font-bold text-white">
            Shop
          </h2>
          <div>
            <li class="px-2 text-left text-sm italic leading-tight text-white">
              Hats give you the chance to get a{" "}
              <span class="font-bold">Bonus Raffle Ticket</span> whenever your
              Beast Levels up!
            </li>
            <li class="px-2 text-left text-sm italic leading-tight text-white">
              Each owned hat gives +5% chance. Limited Edition hats give +15%
            </li>
          </div>
        </div>
        <Show when={!isRouting()} fallback={<div>LOADINGGGG</div>}>
          <Suspense
            fallback={
              <div class="grid grid-cols-2 gap-2 overflow-y-auto bg-[#FFE7DF] p-3">
                <Skeleton
                  class="rounded-lg border border-[#E78882] bg-[#F6B9B0]"
                  height={200}
                />
                <Skeleton
                  class="rounded-lg border border-[#E78882] bg-[#F6B9B0]"
                  height={200}
                />
                <Skeleton
                  class="rounded-lg border border-[#E78882] bg-[#F6B9B0]"
                  height={200}
                />
                <Skeleton
                  class="rounded-lg border border-[#E78882] bg-[#F6B9B0]"
                  height={200}
                />
              </div>
            }
          >
            <div class="grid grid-cols-2 overflow-y-auto p-3 pb-10">
              <For each={shopListingsQuery.data}>
                {(shopItem) => (
                  <ShopItemCard
                    item={shopItem}
                    shopListingRefetch={shopListingRefetch}
                  />
                )}
              </For>
            </div>
          </Suspense>
        </Show>
      </div>
    </PageContainer>
  );
}
