import posthog from "posthog-js";
import { WebApp } from "@/twa-sdk-updated/core";
import { createStore } from "solid-js/store";
import { isDevelopment } from "@/config.ts";
import { testUser } from "@/components/lib/test-token.ts";

export const [clicksAnalyticStore, setClicksAnalyticStore] = createStore({
  feedClicksAnalytics: 0,
  mineClicksAnalytics: 0,
});

export const addClickAnalyticsToStore = (
  clickType: "feedClicksAnalytics" | "mineClicksAnalytics",
) => {
  setClicksAnalyticStore((prev) => {
    return {
      ...prev,
      [clickType]: prev[clickType] + 1,
    };
  });
};

export const resetClicksAnalyticsStore = () => {
  setClicksAnalyticStore({
    feedClicksAnalytics: 0,
    mineClicksAnalytics: 0,
  });
};

const captureEvent = (eventName: string, properties = {}) => {
  // if localhost, set console logs too
  if (window.location.hostname === "localhost") {
    console.log("POSTHOG: Capture event", eventName, properties);
  }

  posthog.capture(eventName, properties);
};

const identifyUser = () => {
  if (!WebApp.initDataUnsafe.user) {
    if (isDevelopment) {
      console.log("POSTHOG: User not found, using test user");
      posthog.identify(testUser.id, {
        username: testUser.username,
      });
    }

    return;
  }

  const userId = WebApp.initDataUnsafe.user.id;
  const username = WebApp.initDataUnsafe.user.username;

  posthog.identify(userId.toString(), {
    username,
  });
};

export { captureEvent, identifyUser };
