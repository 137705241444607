import {
  coinIcon,
  energyBar,
  energyIcon,
  attributePanel,
  faceHappy,
  faceSad,
  faceVeryHappy,
  faceVerySad,
  happinessBar,
  happinessIcon,
  shardIcon,
  xpBar,
  xpIcon,
  arrowUp,
  dailyIcon,
  shopIcon,
  settingsIcon,
  bonusTicketIcon,
  bonusTicketGlow,
  raffleTicketPixel,
} from "@/assets/images";
import { Show } from "solid-js";
import { playerStore, playerStoreSelector } from "@/components/player-store.ts";
import { cn, formatCompactNumber, showOneDecimal } from "@/lib/utils";
import { getExperienceRequirement } from "@/components/lib/levelling.ts";
import "@/up-and-down.css";
import { gameConfig } from "@/components/lib/game-config.ts";
import { A, useNavigate } from "@solidjs/router";
import { createAutoRefreshValue } from "@/lib/hooks.ts";
import "./Views.css";
import { Button } from "./ui/button";
import { captureEvent } from "@/lib/analytics";
import { createQuery } from "@tanstack/solid-query";
import { IS_RANCH_PROD } from "@/config";
import {
  DailyStreakService,
  RaffleTicketsService,
  ShopService,
} from "@/api-client";
import { hudAlertStore } from "./hud-store";
import posthog from "posthog-js";
import { CUSTOM_QUESTS_IMAGES } from "@/assets/custom-quests/custom-quests-images";

function getBarImage(stat: "happiness" | "energy" | "xp") {
  switch (stat) {
    case "happiness":
      return happinessBar;
    case "energy":
      return energyBar;
    case "xp":
      return xpBar;
  }
}

function getIconImage(stat: "happiness" | "energy" | "xp") {
  switch (stat) {
    case "happiness":
      return happinessIcon;
    case "energy":
      return energyIcon;
    case "xp":
      return xpIcon;
  }
}

function getHappinessFace(happiness: number) {
  if (happiness <= 25) {
    return faceVerySad;
  } else if (happiness <= 50) {
    return faceSad;
  } else if (happiness <= 99) {
    return faceHappy;
  } else {
    return faceVeryHappy;
  }
}

type Stat = "happiness" | "energy" | "xp";
const barOffset: Record<Stat, number> = {
  happiness: 70,
  energy: 80,
  xp: 75,
};

function StatBar(props: {
  stat: "happiness" | "energy" | "xp";
  percentage: number;
  level: number;
  showArrow?: boolean;
}) {
  const barImg = getBarImage(props.stat);
  const iconImg = getIconImage(props.stat);
  const faceImg = () => getHappinessFace(props.percentage);

  // The bars graphics start displaying at different offsets, this tries to fix that
  // a bit.
  const insetValue = () => (props.percentage * barOffset[props.stat]) / 100;
  const levelDigits = () => props.level.toString().length;

  return (
    <div class="relative">
      <img src={attributePanel} class="absolute top-0" />
      <img
        src={barImg}
        class="absolute top-0"
        style={{
          "clip-path": `inset(0 ${barOffset[props.stat] - insetValue()}% 0 0)`,
        }}
      />
      <img src={iconImg} class="relative" />
      <Show when={props.stat === "happiness"}>
        <img src={faceImg()} class="absolute left-0 top-0" />
      </Show>
      <Show when={props.stat === "xp"}>
        <div class="text-shade absolute left-0 top-0 flex h-full w-[36%] items-center justify-center text-lg font-bold text-white">
          <span
            class={cn(
              "text-center",
              levelDigits() > 2
                ? "text-sm"
                : levelDigits() > 3
                  ? "text-xs"
                  : "text-lg",
            )}
          >
            {props.level}
          </span>
        </div>
      </Show>
      <Show when={props.showArrow}>
        <img
          src={arrowUp}
          class="up-and-down absolute z-10 mt-2 w-[60px] object-contain"
        />
      </Show>
    </div>
  );
}

export function HUDView(props: {
  happiness: number;
  energy: number;
  coins: number;
  shards: number;
  raffleTickets: number;
  earningRate: number;
  experience: number;
  level: number;
  showArrows?: {
    happiness: boolean;
    energy: boolean;
    xp: boolean;
    coins: boolean;
  };
  showStore?: boolean;
  showBonusTicket?: boolean;
  showDailyStreak?: boolean;
  showSettings?: boolean;
  showBidget?: boolean;
  openDailyStreakModal?: () => void;
  // openLuckySerumSaleModal?: () => void;
  openBonusTicketModal?: () => void;
  openBidgetModal?: () => void;
}) {
  const experienceProgress = () =>
    (props.experience / getExperienceRequirement(props.level)) * 100;
  const dailyStreakState = createQuery(() => ({
    queryKey: ["dailyStreakState"],
    queryFn: () => DailyStreakService.getState(),
    enabled: props.showDailyStreak,
  }));

  // const shopListingsLuckySerumQuery = createQuery(() => ({
  //   queryKey: ["shopListingsLuckySerumQuery"],
  //   queryFn: async () => {
  //     const listing = await ShopService.getShopListings();
  //     const luckySerumListing = listing.find((l) => l.id === "lucky-serum");
  //     return luckySerumListing;
  //   },
  //   enabled: playerStore.beast ? !playerStore.beast.isLucky : false,
  // }));

  let debugClicks = 0;
  let debugClicksTimeout: NodeJS.Timeout;
  const navigate = useNavigate();
  function handleDebugClick() {
    if (IS_RANCH_PROD) {
      return;
    }

    if (debugClicks >= 2) {
      navigate("/play/debug");
      debugClicks = 0;
    } else {
      debugClicks++;
      clearTimeout(debugClicksTimeout);
      debugClicksTimeout = setTimeout(() => {
        debugClicks = 0;
      }, 500);
    }
  }

  return (
    <div class="fixed flex w-full max-w-xl flex-col gap-2 pt-2">
      <div
        class="pointer-events-auto flex w-full gap-2 px-2"
        onClick={handleDebugClick}
      >
        <StatBar
          stat="happiness"
          level={props.level}
          percentage={props.happiness}
          showArrow={props.showArrows?.happiness}
        />
        <StatBar
          stat="xp"
          level={props.level}
          percentage={experienceProgress()}
          showArrow={props.showArrows?.xp}
        />
        <StatBar
          stat="energy"
          level={props.level}
          percentage={props.energy}
          showArrow={props.showArrows?.energy}
        />
      </div>
      <div class="pointer-events-none flex justify-between">
        <div class="flex w-full flex-col px-2 pl-2">
          <div class="relative flex items-center">
            <span class="text-shade flex items-center text-xl font-bold text-white">
              <img src={coinIcon} class="mr-2 h-[25px] w-[25px]" />
              <span class="text-outline eazy-chat mt-2 text-2xl">
                {formatCompactNumber(props.coins)}
              </span>
              <br />
              <span class="text-outline eazy-chat ml-2 mt-2 text-base tracking-widest text-yellow-200">
                +{formatCompactNumber(props.earningRate)}/
                {gameConfig.coins.earningUnit[0]}
              </span>
            </span>
            <Show when={props.showArrows?.coins}>
              <img
                src={arrowUp}
                class="up-and-down absolute -bottom-[70px] z-10 mt-2 w-[80px] object-contain"
              />
            </Show>
          </div>
          <div class="flex min-w-[50px] items-center gap-2">
            <img src={shardIcon} class="h-[25px] w-[25px] object-contain" />
            <span class="text-shade eazy-chat text-outline mt-2 text-2xl font-bold text-white">
              {showOneDecimal(props.shards)}
            </span>
          </div>
          <div class="flex min-w-[50px] items-center gap-2">
            <img
              src={raffleTicketPixel}
              class="h-[25px] w-[25px] object-contain"
            />
            <span class="text-shade eazy-chat text-outline mt-2 text-2xl font-bold text-white">
              {props.raffleTickets}
            </span>
          </div>
        </div>
        <div class="pointer-events-auto flex flex-col">
          <Show when={props.showSettings}>
            <A
              href="/play/settings"
              onClick={() => {
                captureEvent("navigation", { to: "/play/settings" });
              }}
              class="relative flex items-center justify-center p-1"
            >
              <img src={settingsIcon} class="h-14 w-14 object-contain" />
            </A>
          </Show>
          <Show when={props.showDailyStreak}>
            <Button
              class="min-w-auto relative h-auto w-auto p-1 hover:bg-transparent hover:opacity-80"
              variant="ghost"
              onClick={props.openDailyStreakModal}
            >
              <img src={dailyIcon} class="h-14 w-14 object-contain" />
              <Show when={dailyStreakState.data?.isTodayClaimed === false}>
                <div class="absolute bottom-0 left-0 h-5 w-5 rounded-full bg-[#BD1717]">
                  <span class="eazy-chat text-sm text-white">!</span>
                </div>
              </Show>
            </Button>
          </Show>
          <Show when={props.showStore}>
            <A
              href="/play/shop"
              onClick={() => {
                captureEvent("navigation", { to: "/play/shop" });
                // if (playerStore.beast.isLucky) return;
                // if (shopListingsLuckySerumQuery.data?.isPurchased) return;

                // props.openLuckySerumSaleModal?.();
              }}
              class="relative flex items-center justify-center p-1"
            >
              <img src={shopIcon} class="h-14 w-14 object-contain" />
              <Show when={false}>
                <div class="absolute bottom-0 left-0 flex h-5 w-5 items-center justify-center rounded-full bg-[#BD1717] pt-1">
                  <span class="eazy-chat text-center text-sm text-white">
                    !
                  </span>
                </div>
              </Show>
            </A>
          </Show>
          <Show
            when={
              props.showBonusTicket &&
              posthog.isFeatureEnabled("first_raffle_special_signup")
            }
          >
            <Button
              class="min-w-auto relative h-auto w-auto p-1 hover:bg-transparent hover:opacity-100"
              variant="ghost"
              onClick={props.openBonusTicketModal}
            >
              <img
                src={bonusTicketIcon}
                class="h-16 w-20 rounded-full border-[3px] border-black object-contain"
                style={{
                  "background-color": "#b977ba",
                  "background-image": `url(${bonusTicketGlow})`,
                  "background-repeat": "no-repeat",
                  "background-position-y": "center",
                  "background-position-x": "-5px",
                  "background-size": "120% 120%",
                }}
              />
            </Button>
          </Show>
          <Show when={props.showBidget && posthog.isFeatureEnabled("bitget")}>
            <Button
              class="min-w-auto relative h-auto w-auto p-1 hover:bg-transparent hover:opacity-100"
              variant="ghost"
              onClick={props.openBidgetModal}
            >
              <img
                src={CUSTOM_QUESTS_IMAGES["BitgetGlow"]}
                class="h-16 w-20 rounded-full object-contain"
              />
            </Button>
          </Show>
        </div>
      </div>
    </div>
  );
}

export function HUD(props: {
  openDailyStreakModal?: () => void;
  // openLuckySerumSaleModal?: () => void;
  openBonusTicketModal?: () => void;
  openBidgetModal?: () => void;
}) {
  const coins = createAutoRefreshValue(playerStoreSelector.coins);
  const earningRate = createAutoRefreshValue(
    playerStoreSelector.coinsEarningRate,
  );
  const energy = createAutoRefreshValue(playerStoreSelector.energy);
  const happiness = createAutoRefreshValue(playerStoreSelector.beastHappiness);

  const ticketsCountQuery = createQuery(() => ({
    queryKey: ["raffleTickets"],
    queryFn: RaffleTicketsService.getRaffleTickets,
  }));

  return (
    <div class="pointer-events-none">
      <HUDView
        coins={coins()}
        earningRate={earningRate()}
        shards={playerStore.shards}
        raffleTickets={ticketsCountQuery.data?.count ?? 0}
        happiness={happiness()}
        energy={energy()}
        experience={playerStore.beast.experience}
        level={playerStore.beast.level}
        openDailyStreakModal={props.openDailyStreakModal}
        // openLuckySerumSaleModal={props.openLuckySerumSaleModal}
        openBonusTicketModal={props.openBonusTicketModal}
        openBidgetModal={props.openBidgetModal}
        showStore={true}
        showDailyStreak={true}
        showSettings={true}
        showBonusTicket={true}
        showBidget={true}
      />
    </div>
  );
}
